import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`UI/UX & Product`}</h2>
    <p>{`During my time in Tecsisa I learned best development practices (including agile), UI/UX, and HTML/CSS programming. I handled the front end development of various projects and participated in the product concept for a solution in the energy sector. `}</p>
    <div className="row my-5">
      <div className="col-md-3 offset-md-5">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/6eb596c5d86e407357179a93a9c8c10d/bcec7/tecsisa-08.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIFBgME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAfNC6kZ9fpc5IOAP/8QAHBAAAgICAwAAAAAAAAAAAAAAAAEDFAIREBIh/9oACAEBAAEFAtrq+KsBUhKkDMfVito//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGRAAAgMBAAAAAAAAAAAAAAAAAKEQMTIg/9oACAEBAAY/AqnLKZl8f//EABwQAQADAAIDAAAAAAAAAAAAAAEAEZEhMVFhcf/aAAgBAQABPyEQcJWWTmoW57O4AEVfcHauwbVdlvl2f//aAAwDAQACAAMAAAAQhOc8/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQAQIf/aAAgBAwEBPxDIJf8A/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhEP/aAAgBAgEBPxCoo8//xAAcEAEBAQEAAgMAAAAAAAAAAAABEQAhMfCRweH/2gAIAQEAAT8QMwWBTzkEG1SfOasLgZts9XDXvPXzn6Ecf10noF7W6Ea+Hm/2N//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Tecsisa",
                "title": "Tecsisa",
                "src": "/static/6eb596c5d86e407357179a93a9c8c10d/4b190/tecsisa-08.jpg",
                "srcSet": ["/static/6eb596c5d86e407357179a93a9c8c10d/e07e9/tecsisa-08.jpg 200w", "/static/6eb596c5d86e407357179a93a9c8c10d/066f9/tecsisa-08.jpg 400w", "/static/6eb596c5d86e407357179a93a9c8c10d/4b190/tecsisa-08.jpg 800w", "/static/6eb596c5d86e407357179a93a9c8c10d/bcec7/tecsisa-08.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/4f562db3643a5e77204c839e0dec2df7/bcec7/tecsisa-06.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBAv/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB73K0JlTNiCIV/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAIBEBEDIf/aAAgBAQABBQKPDTa5skLLIZf/xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BI//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABcQAQADAAAAAAAAAAAAAAAAACAAIjH/2gAIAQEABj8CFpg//8QAGhAAAwEBAQEAAAAAAAAAAAAAAAERUXEhkf/aAAgBAQABPyFkkR0dCICLcGn4nwjCLERYj//aAAwDAQACAAMAAAAQZOfD/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQAQcf/aAAgBAwEBPxAJq//EABgRAAIDAAAAAAAAAAAAAAAAAAABEBFh/9oACAECAQE/EGy8n//EAB4QAAICAQUBAAAAAAAAAAAAAAABEVEhMUFxkfGh/9oACAEBAAE/EEBqSWENVtnkhXsZWIiGmv8ATHMooZdHR4x4x//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Tecsisa",
                "title": "Tecsisa",
                "src": "/static/4f562db3643a5e77204c839e0dec2df7/4b190/tecsisa-06.jpg",
                "srcSet": ["/static/4f562db3643a5e77204c839e0dec2df7/e07e9/tecsisa-06.jpg 200w", "/static/4f562db3643a5e77204c839e0dec2df7/066f9/tecsisa-06.jpg 400w", "/static/4f562db3643a5e77204c839e0dec2df7/4b190/tecsisa-06.jpg 800w", "/static/4f562db3643a5e77204c839e0dec2df7/bcec7/tecsisa-06.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row my-5">
      <div className="col-md-3 offset-md-2">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/1eede259cd4c5c068da351c6cfb33968/bcec7/tecsisa-12.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAMEBQH/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHRpadWs9F1OkMAK6B//8QAHBAAAwACAwEAAAAAAAAAAAAAAQIDAAQQERQx/9oACAEBAAEFAtirITtU7HytVmRuTJx5q+eWXH//xAAXEQEAAwAAAAAAAAAAAAAAAAAQETFB/9oACAEDAQE/Adsg/8QAFxEAAwEAAAAAAAAAAAAAAAAAASAxMv/aAAgBAgEBPwE5if/EAB4QAAICAAcAAAAAAAAAAAAAAAERABACEiEiMpGh/9oACAEBAAY/Ahlg0HVBuJYq3Tj7X//EABwQAQEBAAMAAwAAAAAAAAAAAAERACFBUTFhkf/aAAgBAQABPyEPjk7MH4C8zFQ+mFCqdYZBfTDQc+RZ97ntfrBCG//aAAwDAQACAAMAAAAQ2w+A/8QAGREBAAMBAQAAAAAAAAAAAAAAARARITFB/9oACAEDAQE/EKVpx5FNyP/EABcRAQEBAQAAAAAAAAAAAAAAAAEQEYH/2gAIAQIBAT8QQYeoqmT/xAAeEAEBAQACAQUAAAAAAAAAAAABEQAhMUFxobHB0f/aAAgBAQABPxAEWItHzhdR9hZPXW7hBy05kkfvW5lKEPfEJ0lyEqEIj4wJ5R5f0wF0BDf/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Tecsisa",
                "title": "Tecsisa",
                "src": "/static/1eede259cd4c5c068da351c6cfb33968/4b190/tecsisa-12.jpg",
                "srcSet": ["/static/1eede259cd4c5c068da351c6cfb33968/e07e9/tecsisa-12.jpg 200w", "/static/1eede259cd4c5c068da351c6cfb33968/066f9/tecsisa-12.jpg 400w", "/static/1eede259cd4c5c068da351c6cfb33968/4b190/tecsisa-12.jpg 800w", "/static/1eede259cd4c5c068da351c6cfb33968/bcec7/tecsisa-12.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b79c348375408986494d55434785a7ed/bcec7/tecsisa-11.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAetZTBDSpDdY1BX/xAAaEAACAwEBAAAAAAAAAAAAAAABAgADERIQ/9oACAEBAAEFAluDTuB9gQKUTDWnI9//xAAWEQADAAAAAAAAAAAAAAAAAAABESD/2gAIAQMBAT8BZj//xAAWEQADAAAAAAAAAAAAAAAAAAABESD/2gAIAQIBAT8BQj//xAAdEAABAgcAAAAAAAAAAAAAAAABAAIQESAxQVFh/9oACAEBAAY/AnciZZRO1ej/xAAaEAEBAQADAQAAAAAAAAAAAAABEQAgITFB/9oACAEBAAE/IWQCXG4C9RmM0HMBlV+5Lz3vnmrnat4f/9oADAMBAAIAAwAAABB7/wA8/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQAQESH/2gAIAQMBAT8QdOQXL//EABgRAQADAQAAAAAAAAAAAAAAAAEAESEQ/9oACAECAQE/ECjYDW9//8QAHRABAAICAgMAAAAAAAAAAAAAAREhADEQQWGRof/aAAgBAQABPxC/mdC29X4xcJNgZjG4UMXiKLrLL3reC+aQI7NfcdtzZJHRzGf/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Tecsisa",
                "title": "Tecsisa",
                "src": "/static/b79c348375408986494d55434785a7ed/4b190/tecsisa-11.jpg",
                "srcSet": ["/static/b79c348375408986494d55434785a7ed/e07e9/tecsisa-11.jpg 200w", "/static/b79c348375408986494d55434785a7ed/066f9/tecsisa-11.jpg 400w", "/static/b79c348375408986494d55434785a7ed/4b190/tecsisa-11.jpg 800w", "/static/b79c348375408986494d55434785a7ed/bcec7/tecsisa-11.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/5d67b256ed70f250586afa8877546535/bcec7/tecsisa-09.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB7MRDRRWgtgr/xAAaEAACAgMAAAAAAAAAAAAAAAABAgAREiAx/9oACAEBAAEFAizXk0Ukg9i6f//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABcQAAMBAAAAAAAAAAAAAAAAAAAgQUL/2gAIAQEABj8Chkj/AP/EAB4QAAIBAwUAAAAAAAAAAAAAAAERACBBcSExUWGR/9oACAEBAAE/IWCv5nT6h8TdoozZiJtNhzR//9oADAMBAAIAAwAAABA38Dz/xAAZEQABBQAAAAAAAAAAAAAAAAAAARAhMVH/2gAIAQMBAT8QnRKf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQITH/2gAIAQIBAT8Qoez/AP/EAB4QAQACAQQDAAAAAAAAAAAAAAEAESExUWFxINHw/9oACAEBAAE/EBCWCMq4OWUdvU2A61cfXC8Dsq+OI0MqdddmGiz28P/Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Tecsisa",
                "title": "Tecsisa",
                "src": "/static/5d67b256ed70f250586afa8877546535/4b190/tecsisa-09.jpg",
                "srcSet": ["/static/5d67b256ed70f250586afa8877546535/e07e9/tecsisa-09.jpg 200w", "/static/5d67b256ed70f250586afa8877546535/066f9/tecsisa-09.jpg 400w", "/static/5d67b256ed70f250586afa8877546535/4b190/tecsisa-09.jpg 800w", "/static/5d67b256ed70f250586afa8877546535/bcec7/tecsisa-09.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <h2>{`Marketing`}</h2>
    <p>{`I joined Tecsisa as a graphic designer, became a digital marketer and eventually their core marketing staff managing a small team. We updated the logo, redesigned the brand (including office decoration), website, photographed staff and organized internal events. `}</p>
    <div className="row my-5">
      <div className="col-md-3 offset-md-2">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e590efe7a456843e7a79ea163888c662/bcec7/tecsisa-03.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAByarL5OGIumVnB//EABwQAAICAgMAAAAAAAAAAAAAAAIDAAEhMQQRFP/aAAgBAQABBQJaTKecui3xjuMdQUzJXgYe/wD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAbEAABBQEBAAAAAAAAAAAAAAARAAEQEiExYf/aAAgBAQAGPwLGxHsUKqS/kPP/xAAdEAADAAICAwAAAAAAAAAAAAAAARExQSFRYYGx/9oACAEBAAE/IViUMqRfVpZHg3NdMcH8fP2VS8tD1Lh9kPmf/9oADAMBAAIAAwAAABA3KED/xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAwEBPxBI/8QAFhEBAQEAAAAAAAAAAAAAAAAAASAx/9oACAECAQE/EBDY/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFBUWFxsf/aAAgBAQABPxDW3KXjFiCrHkTnfPzJ5esmUmLLn1migLCD64P3DbqhXtxEbpAMSvWQQDWOGeGf/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Tecsisa",
                "title": "Tecsisa",
                "src": "/static/e590efe7a456843e7a79ea163888c662/4b190/tecsisa-03.jpg",
                "srcSet": ["/static/e590efe7a456843e7a79ea163888c662/e07e9/tecsisa-03.jpg 200w", "/static/e590efe7a456843e7a79ea163888c662/066f9/tecsisa-03.jpg 400w", "/static/e590efe7a456843e7a79ea163888c662/4b190/tecsisa-03.jpg 800w", "/static/e590efe7a456843e7a79ea163888c662/bcec7/tecsisa-03.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/041cbc17b3e14e967c0d53aab7fed6b9/bcec7/tecsisa-05.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQDAQL/xAAXAQEBAQEAAAAAAAAAAAAAAAAEAQAD/9oADAMBAAIQAxAAAAGjm+R0zKyjXDnfY0//xAAbEAADAAIDAAAAAAAAAAAAAAAAAQIDERASMf/aAAgBAQABBQJvb8O5khih0PBwqNH/xAAaEQABBQEAAAAAAAAAAAAAAAADAAECEBET/9oACAEDAQE/AescTmJf/8QAGREAAgMBAAAAAAAAAAAAAAAAAQMAAhAT/9oACAECAQE/AeViYEr3/8QAGhAAAQUBAAAAAAAAAAAAAAAAIQABEBEgIv/aAAgBAQAGPwKeGtEZ/8QAGhABAQEBAAMAAAAAAAAAAAAAAQARIUFRYf/aAAgBAQABPyHYXzCrSeuGwkV9if5T9k23CRst/9oADAMBAAIAAwAAABAT37//xAAZEQACAwEAAAAAAAAAAAAAAAAAARARIcH/2gAIAQMBAT8Qo5GXg4//xAAZEQADAAMAAAAAAAAAAAAAAAAAARExobH/2gAIAQIBAT8QVjYio+iwVn//xAAeEAEBAQABBAMAAAAAAAAAAAABEQAhMVFhcUHR4f/aAAgBAQABPxB3VeTuFFiXtdJEh82Y0I17HrOfDRF/Mjgk1skfeDnAeDdYrfR9b//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Tecsisa",
                "title": "Tecsisa",
                "src": "/static/041cbc17b3e14e967c0d53aab7fed6b9/4b190/tecsisa-05.jpg",
                "srcSet": ["/static/041cbc17b3e14e967c0d53aab7fed6b9/e07e9/tecsisa-05.jpg 200w", "/static/041cbc17b3e14e967c0d53aab7fed6b9/066f9/tecsisa-05.jpg 400w", "/static/041cbc17b3e14e967c0d53aab7fed6b9/4b190/tecsisa-05.jpg 800w", "/static/041cbc17b3e14e967c0d53aab7fed6b9/bcec7/tecsisa-05.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b5994f7b8c35538fec8b13f54f83d48f/bcec7/tecsisa-04.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAMEAQIF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABuKGaXXbM6jrDJAj/xAAdEAACAgEFAAAAAAAAAAAAAAACAwABBBETITEy/9oACAEBAAEFAt8adZExnMNAW3GYdN1nRWkSg+f/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAECAQE/AYiCz//EACAQAAEDAwUBAAAAAAAAAAAAAAEAAhEQIUEDEiIxMnH/2gAIAQEABj8CDCDfKDW8QO6BmnM/fK2PdApZPnNP/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBcVH/2gAIAQEAAT8hybtOI7Vu31qUIgdeV0g6ALKTb7HKIMgt3MUPfj9IMC1rrP/aAAwDAQACAAMAAAAQbCC8/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EDaiv//EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQIBAT8QCNLE2e3/xAAcEAEAAgIDAQAAAAAAAAAAAAABESEAMUFRYYH/2gAIAQEAAT8QTYMDRuUnd1hN0aDI0eYtgJO8iTifIuPuqxwOKci4Ibm/cIshggEMgtjU403TFeg+DjCmgJJWuXP/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Tecsisa",
                "title": "Tecsisa",
                "src": "/static/b5994f7b8c35538fec8b13f54f83d48f/4b190/tecsisa-04.jpg",
                "srcSet": ["/static/b5994f7b8c35538fec8b13f54f83d48f/e07e9/tecsisa-04.jpg 200w", "/static/b5994f7b8c35538fec8b13f54f83d48f/066f9/tecsisa-04.jpg 400w", "/static/b5994f7b8c35538fec8b13f54f83d48f/4b190/tecsisa-04.jpg 800w", "/static/b5994f7b8c35538fec8b13f54f83d48f/bcec7/tecsisa-04.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row my-5">
      <div className="col-md-3 offset-md-2">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8e6db8796508f53dca3621c57ba268a7/bcec7/tecsisa-07.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAMBBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHvYiLoDZAB/8QAGRAAAgMBAAAAAAAAAAAAAAAAABEBAhIg/9oACAEBAAEFAnBqBmGZuK/H/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGRAAAgMBAAAAAAAAAAAAAAAAADEQIKEh/9oACAEBAAY/Ahx0eDyn/8QAHhAAAgEDBQAAAAAAAAAAAAAAAAERIGHwQVFxkeH/2gAIAQEAAT8huFkQ3HImjcX9G1Rl2R6cc0f/2gAMAwEAAgADAAAAEOPAAP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB0QAQACAQUBAAAAAAAAAAAAAAEAEWEgITFRkfH/2gAIAQEAAT8QxfZ9qYXsdhBRSIuA870KTqA1CGbaH//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Tecsisa",
                "title": "Tecsisa",
                "src": "/static/8e6db8796508f53dca3621c57ba268a7/4b190/tecsisa-07.jpg",
                "srcSet": ["/static/8e6db8796508f53dca3621c57ba268a7/e07e9/tecsisa-07.jpg 200w", "/static/8e6db8796508f53dca3621c57ba268a7/066f9/tecsisa-07.jpg 400w", "/static/8e6db8796508f53dca3621c57ba268a7/4b190/tecsisa-07.jpg 800w", "/static/8e6db8796508f53dca3621c57ba268a7/bcec7/tecsisa-07.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e09482842336b5cb800076c7e0d72377/bcec7/tecsisa-01.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMCAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB98mCTJK8FgE//8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAAiFB/9oACAEBAAEFApbQuIr/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ah//xAAaEAADAQADAAAAAAAAAAAAAAAAATERECFB/9oACAEBAAE/IdxPUNAdO/RtB3iB0//aAAwDAQACAAMAAAAQYCg9/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQMBAT8QY//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExQVFxof/aAAgBAQABPxDQDlg2DIdZ9ipbBXUIdfUZ0hpzHVDzHH//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Tecsisa",
                "title": "Tecsisa",
                "src": "/static/e09482842336b5cb800076c7e0d72377/4b190/tecsisa-01.jpg",
                "srcSet": ["/static/e09482842336b5cb800076c7e0d72377/e07e9/tecsisa-01.jpg 200w", "/static/e09482842336b5cb800076c7e0d72377/066f9/tecsisa-01.jpg 400w", "/static/e09482842336b5cb800076c7e0d72377/4b190/tecsisa-01.jpg 800w", "/static/e09482842336b5cb800076c7e0d72377/bcec7/tecsisa-01.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b32e3af321357db2f5ff5b052f09cee2/bcec7/tecsisa-10.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAQEAAwAAAAAAAAAAAAAAAAQCAQMF/8QAFwEBAAMAAAAAAAAAAAAAAAAAAgABA//aAAwDAQACEAMQAAABpgxZoeWBVfSGeqDf/8QAHhAAAgEDBQAAAAAAAAAAAAAAAgMAAQQUBRESEzH/2gAIAQEAAQUCK2XWEPQ3KYUBh83LGhYO8FICz3UJ/8QAFxEAAwEAAAAAAAAAAAAAAAAAAREgMf/aAAgBAwEBPwFjY//EABkRAAEFAAAAAAAAAAAAAAAAAAABECEiMf/aAAgBAgEBPwGcKiN//8QAIBABAAIBAwUBAAAAAAAAAAAAAQARAhIhMQMQMkFhgv/aAAgBAQAGPwL2TG89QN1LDph9YGDzNy3luec1BvPz2//EAB0QAAICAwADAAAAAAAAAAAAAAABITERQVEQYaH/2gAIAQEAAT8hp2/q2KTqKEh6QlM8Mq0RccmzbIroJ1BAf8HSceP/2gAMAwEAAgADAAAAECsvwv/EABcRAQEBAQAAAAAAAAAAAAAAAAEAECH/2gAIAQMBAT8QQJ2TP//EABoRAQACAwEAAAAAAAAAAAAAAAEAERAhcfD/2gAIAQIBAT8QC1meajW+Y//EAB8QAQADAAIBBQAAAAAAAAAAAAEAESExQWFxkaHB4f/aAAgBAQABPxA08xrF20/Uq2ogRALMcFlu24+U2KKEC+r1IV7nmWOuArAi2I2BuhXc4sKqH3VByqMh44/ZbP/Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Tecsisa",
                "title": "Tecsisa",
                "src": "/static/b32e3af321357db2f5ff5b052f09cee2/4b190/tecsisa-10.jpg",
                "srcSet": ["/static/b32e3af321357db2f5ff5b052f09cee2/e07e9/tecsisa-10.jpg 200w", "/static/b32e3af321357db2f5ff5b052f09cee2/066f9/tecsisa-10.jpg 400w", "/static/b32e3af321357db2f5ff5b052f09cee2/4b190/tecsisa-10.jpg 800w", "/static/b32e3af321357db2f5ff5b052f09cee2/bcec7/tecsisa-10.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      